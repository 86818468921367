/*======================================
 11. Home-style-8
 =======================================*/

/*11.1 wpo-category-section*/

.wpo-category-section {
	padding: 50px 0 20px;

	.wpo-category-wrap {
		padding: 0 40px;

		@include media-query(767px) {
			padding: 0px;
		}

		.wpo-category-item {
			position: relative;
			margin-bottom: 30px;

			.wpo-category-text {
				text-align: center;

				a {
					display: block;
					padding: 15px 35px 20px;
					background: rgba(255, 255, 255, 0.7);
					color: $dark-gray;
					font-weight: 400;
					font-size: 35px;
					line-height: 50px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					font-family: $heading-font;
					width: 100%;
					max-width: 470px;

					@media(max-width:1600px) {
						font-size: 30px;
						max-width: 370px;
					}

					@media(max-width:1399px) {
						font-size: 20px;
						max-width: 270px;
						line-height: 36px;
					}

					@media(max-width:767px) {
						font-size: 18px;
						max-width: 200px;
						padding: 10px 10px 15px;
					}

					&:hover {
						background: $theme-primary-color-s2;
						color: $white;
					}
				}
			}
		}
	}
}



/*11.2 wpo-offer-banner-section*/

.wpo-offer-banner-section {
	background: url(../../images/offer.jpg) no-repeat center center;
	height: 600px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding-left: 150px;

	@media(max-width:1399px) {
		padding-left: 50px;
	}

	@media(max-width:1199px) {
		padding-left: 0px;
	}

	@media(max-width:991px) {
		height: 500px;
	}

	@media(max-width:575px) {
		height: 400px;
	}


	.offer-banner-text {
		padding: 20px;
		background: rgba(255, 255, 255, 0.9);
		max-width: 1022px;

		@media(max-width:575px) {
			padding: 10px;
			text-align: center;
		}

		.offer-banner-text-inner {
			padding: 80px;
			border: 1px solid $theme-primary-color;

			@media(max-width:991px) {
				padding: 40px;
				text-align: center;
			}

			@media(max-width:575px) {
				padding: 20px;
				text-align: center;
			}

		}

		span {
			font-weight: 400;
			font-size: 35px;
			line-height: 51px;
			color: $theme-primary-color;

			@media(max-width:991px) {
				font-size: 25px;
				line-height: 40px;
			}

			@media(max-width:575px) {
				font-size: 18px;
				line-height: 25px;
			}
		}

		h2 {
			font-weight: 400;
			font-size: 80px;
			line-height: 116px;
			margin-bottom: 20px;
			font-family: $base-font;

			@media(max-width:1200px) {
				font-size: 70px;
			}


			@media(max-width:991px) {
				font-size: 50px;
				line-height:80px;
			}

			@media(max-width:767px) {
				font-size: 40px;
			}
			@media(max-width:575px) {
				font-size: 30px;
				line-height: 42px;
			}
		}
	}
}

.load-btn{
	text-align: center;
	margin-top: 20px;
}