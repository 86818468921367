/*======================================
 9. Home-style-6
 =======================================*/

/*9.1 wpo-Service-section-s3*/

.wpo-Service-section-s3 {
	padding-bottom: 90px;

	.wpo-Service-item {
		padding: 0;
		box-shadow: none;
		margin-bottom: 30px;

		.wpo-Service-img {
			border-radius: 10px;

			img {
				border-radius: 10px;
			}
		}

		.wpo-Service-text {
			a {
				bottom: 15px;
			}

			h2 {
				font-weight: 400;
				font-size: 50px;
				line-height: 72px;
				text-align: center;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				color: $white;

				@media(max-width:1399px) {
					font-size: 30px;
					line-height: 55px;
				}

				@media(max-width:991px) {
					font-size: 50px;
					line-height: 72px;
				}

				@media(max-width:767px) {
					font-size: 30px;
					line-height: 55px;
				}

				@media(max-width:575px) {
					font-size: 50px;
					line-height: 72px;
				}

				@media(max-width:450px) {
					font-size: 40px;
					line-height: 55px;
				}
			}
		}
	}
}

/*9.2 wpo-portfolio-section-s4*/

.wpo-portfolio-section-s4 {
	padding-bottom: 100px;

	@media(max-width:991px) {
		padding-bottom: 70px;
	}

	@media(max-width:767px) {
		padding-bottom: 50px;
	}

	@media(max-width:575px) {
		padding-bottom: 30px;
	}

	.portfolio-grids {

		.grid {
			position: relative;
			overflow: hidden;

			img {
				width: 100%;
				transition: all .3s;
				transform: scale(1);
			}

			.img-holder{
				overflow: hidden;
			}

			.portfolio-content {
				position: absolute;
				left: 50%;
				bottom: -200px;
				background: $white;
				width: 100%;
				max-width: 300px;
				transform: translateX(-50%);
				font-family: $base-font;
				text-align: center;
				padding: 20px;
				transition: all .3s;


				h4 {
					font-weight: 400;
					font-size: 25px;
					line-height: 36px;
					text-align: center;
					font-family: $base-font;
					margin-bottom: 10px;
				}
			}


			&:hover {
				.portfolio-content {
					bottom: 20px;
				}

				img {
					transform: scale(1.2);
				}
			}
		}
	}
}