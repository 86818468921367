body {
  font-family: 'Poppins', sans-serif;
}

.print-only {
    background: white;
    min-height: 100vh;
}

.avoid-break {
    page-break-inside: avoid;
}

.page-break {
    page-break-before: always;
}

.bg-gradient-light {
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
}

.agreement-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.card-custom {
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.card-custom:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.text-primary {
  color: #2c3e50 !important;
}

.text-success {
  color: #27ae60 !important;
}

.text-danger {
  color: #c0392b !important;
}

.agreement-text {
  line-height: 1.8;
  font-size: 1.1rem;
  text-align: justify;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.agreement-text p {
  margin-bottom: 1.2rem;
}

.section-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.section-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #2c3e5024;
}

.otp-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
}

.otp-input {
    width: 70px;
    height: 70px;
    border: 2px solid #dee2e6;
    border-radius: 8px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    transition: all 0.3s ease;
    color: #333;
}

.otp-input:focus {
    border-color: #0d6efd;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.otp-input::placeholder {
    color: #ccc;
}

.payment-details {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.05);
}

@media print {
    .print-only {
        margin: 0;
        padding: 20px !important;
    }
    
    .container {
        width: 100%;
        max-width: none;
    }
    
    body {
        background: white;
    }

    .agreement-container {
        box-shadow: none;
        padding: 20px;
    }
    
    .card-custom {
        break-inside: avoid;
    }
    
    .avoid-break {
        page-break-inside: avoid;
    }
    
    .page-break {
        page-break-before: always;
    }
}

/* Responsive tasarım için */
@media (max-width: 768px) {
    .otp-container {
        gap: 10px;
    }
    
    .otp-input {
        width: 70px;
        height: 70px;
        font-size: 20px;
    }
    
    .agreement-container {
        padding: 15px;
    }
}
